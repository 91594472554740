<template>
  <div>
    <b-card bg-variant="warning">
      <b-card-body
        v-for="(item, i) in items"
        :key="i"
      >
        <b-media>
          <template #aside>
            <b-avatar src="/infowing.png" size="4rem" class="mr-3" />
          </template>
          <template #default>
            <div class="d-flex flex-row align-items-start justify-content-between">
              <div>
                <h5 class="my-0">
                  <span id="notePrivateCreateAt">{{ $dateFormat(item.created_at) }}</span>
                </h5>
                <p class="mb-0">
                  {{ item.text }}
                </p>
              </div>
            </div>
          </template>
        </b-media>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'LeaveMotivation',
  props: {
    itemId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      newNote: ''
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'info'
    ]),
    items () {
      return (this.info.profile && this.info.profile.leave_motivation) || []
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
